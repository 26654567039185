import React from "react"
import { v4 as uuidv4 } from "uuid"
import Slider from "react-slick"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  slidesToScroll: 1,
  draggable: false,
  arrows: false,
  focusOnSelect: false,
}

export default function QuotePanel({ person, title, text, cls }) {
  return (
    <div>
      {title && <h1 className="quote-panel-title">{title}</h1>}
      <Slider {...settings}>
        {testimonials.map((t, i) => (
          <div className={`quote-panel ${cls}`} key={uuidv4()}>
            <div className="testimonials_head">
              <div className="left_line"></div>
              <div className="center_image">
                <FaQuoteLeft className="quote-left" />
              </div>
              <div className="right_line"></div>
            </div>
            <div className="testimonials_txt">
              <p>{t.text}</p>
              <p>
                from {t.name} - {t.position}
              </p>
            </div>
            <div className="testimonials_foot">
              <div className="left_line"></div>
              <div className="center_image">
                <FaQuoteRight className="quote-right" />
              </div>
              <div className="right_line"></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

QuotePanel.defaultProps = {
  cls: "purple",
  person: "",
  text: "A nice review....",
  title: "",
}

const testimonials = [
  {
    name: "Dan Crosby",
    position: "Grit and Grain Co.",
    text: `Great products supplied, cut and delivered by wonderful folks! Couldn't be happier with their work.`,
  },
  {
    name: "Ciera Rice",
    position: "",
    text: `I've experienced nothing but EXTRAORDINARY customer service with this company!!! My husband and I will continue to go through them for all of our glass needs. Very well pleased! Would rate more than 5 stars if I could!`,
  },
  {
    name: "Gene Townsend",
    position: "",
    text: `Great Service and on Time Deliveries for Custom Glass`,
  },
  {
    name: "John Butler",
    position: "",
    text: `Great company.  Staff is so helpful and knowledgeable.`,
  },
  {
    name: "Coral Smolen",
    position: "",
    text: `I needed a last minute order of cabinet door inserts and Karen Tagle not only responded back to me fast and kept in contact with me along the way but helped to rush my project and have it specially wrapped and shipped. Thank you, Karen! You rock!`,
  },
]
